<template>
  <!-- 
    画面: プランｘ部屋タイプ編集  削除タブ
    用途: プランｘ部屋タイプを削除する
   -->
  <div>

    <!-- #region Action field -->
    <template>
      <!-- #region 削除ボタン -->
      <CButton
        class="px-5 mt-4"
        color="danger"
        @click="onDeleteClicked()"
      >
        <i class="cil-trash"></i> {{ $t("common.delete") }}
      </CButton>
      <!-- #endregion 削除ボタン -->
    </template>
    <!-- #endregion Action field -->

    <!-- #region Modal -->
    <template>

      <DeleteConfirmModal
        :deleteConfirmModal="deleteConfirmModal"
        :callback="reqDelete"
        @close="deleteConfirmModal = $event"
      />

      <SuccessModal
        :successModal="successModal"
        @close="successModal = $event"
      />

      <ErrorModal
        :errorModal="errorModal"
        :errorMessage="errorMessage"
        @close="errorModal = $event"
      />

    </template>
    <!-- #endregion Modal -->

  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PlanEditDelete",

  components: {
  },

  props: {},

  watch: {
    successModal(newValue, oldValue) {
      // 削除後のSuccessModalを閉じたら前画面に戻る
      if (!newValue && oldValue) this.backPage();
    },
  },

  data() {
    return {
      //#region Flag
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,
      //#endregion Flag


      //#region Response data
      response: [],
      //#endregion Response data


      //#region Response data
      errorMessage: "",
      //#endregion Response data
    }
  },

  methods: {
    //#region Event
    onDeleteClicked() {
      this.deleteConfirmModal = true;
    },
    //#endregion Event


    //#region Request
    reqDelete() {
      this.deleteConfirmModal = false;

      const url = `/rest/hotel/${this.uid}/plan/${this.planId}`;
      console.log(url);

      axios
        .delete(url, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.deleteSuccessModal = true;
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = `${err.name} : ${err.message}`;
          this.errorModal = true;
        });
    },
    //#endregion Request
  },

};
</script>
