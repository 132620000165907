<template>
  <!-- 
    画面: プラン編集  その他タブ
    用途: プランの基本設定以外を設定する
   -->
  <div class="plan-others-from-edit">
    <!-- #region Input field -->
    <template>

      <!-- #region プラン特徴 -->
      <CRow class="mt-3">
        <CCol class="label"><strong>{{ $t("planEditPage.planFeature") }}</strong></CCol>
        <CCol class="i-field">
          <div class="form-inline">
            <CInputCheckbox
              class="mx-1 mt-2"
              v-for="(option, n) in edit.features"
              :key="n + option"
              :label="option.feature.name"
              :value="option.featureId"
              custom
              :checked.sync="option.enabled"
            />
          </div>
        </CCol>
      </CRow>
      <!-- #endregion プラン特徴 -->

      <!-- #region プランカテゴリ -->
      <CRow class="mt-3">
        <CCol class="label"><strong>{{ $t("planEditPage.planCagetory") }}</strong></CCol>
        <CCol class="i-field">
          <div class="form-inline">
            <CInputCheckbox
              class="mx-1 mt-2"
              v-for="(option, n) in planCategoryList"
              :key="n + option"
              :label="option.name"
              :value="option.id"
              custom
              :checked.sync="option.enabled"
            />
          </div>
        </CCol>
      </CRow>
      <!-- #endregion プランカテゴリ -->

      <!-- #region オプション -->
      <CRow>
        <CCol class="label"><strong>{{ $t("planEditPage.option") }}</strong></CCol>
        <CCol class="i-field py-2">
          <EnableSelectionList
            :list="optionList"
            :leftListTitle="$t('common.active')"
            :rightListTitle="$t('common.inActive')"
          />
        </CCol>
      </CRow>
      <!-- #endregion オプション -->

      <!-- #region シークレット設定 -->
      <CRow>
        <CCol class="label"><strong>{{ $t("planEditPage.secretPreference") }}</strong></CCol>
        <CCol class="i-field">
          <CInputCheckbox
            class="mx-1 mt-2"
            :checked.sync="edit.secret"
            :label="'シークレット'"
            custom
          />
        </CCol>
      </CRow>
      <!-- #endregion シークレット設定 -->

      <!-- #region キーワード -->
      <!-- TODO: 取得/更新できていない -->
      <CRow v-if="edit.secret">
        <CCol class="label"><strong>{{ $t("planEditPage.keyword") }}</strong></CCol>
        <CCol class="i-field">
          <CInput v-model="edit.accessCode" />
        </CCol>
      </CRow>
      <!-- #endregion キーワード -->
    </template>
    <!-- #endregion Input field -->

    <!-- #region 更新ボタン -->
    <CButton
      class="px-5 mt-4"
      color="success"
      @click="onUpdateClicked()"
      v-bind:class="activeStatus"
    >
      <i class="cil-save"></i> {{ $t("common.update") }}
    </CButton>
    <!-- #endregion 更新ボタン -->

  </div>
</template>

<script>
import axios from "axios";
// import i18n from "@/i18n";
import EnableSelectionList from "@/components/EnableSelectionList";
import MASTER_TYPE from "@/mixins/property";
import STATUS from "@/mixins/property";
import utils from "@/mixins/utils";

export default {
  name: "PlanEditTag",

  mixins: [MASTER_TYPE, STATUS, utils],

  components: {
    EnableSelectionList,
  },

  props: {},

  data() {
    return {
      //#region Flag
      loading: false,
      successModal: false,
      errorModal: false,
      //#endregion Flag


      //#region Edit
      edit: {
        id: "plan01",
        planFeature: [
          { name: "planFeature a", enabled: true },
          { name: "planFeature b", enabled: true },
        ],
        planCagetories: [
          { name: "planCagetory a", enabled: true },
          { name: "planCagetory b", enabled: true },
        ],
        optionUse: "none",
        options: [
          { name: "option 01", enabled: false },
          { name: "option 02", enabled: false },
          { name: "option 03", enabled: false },
          { name: "option 04", enabled: false },
          { name: "option 05", enabled: false },
          { name: "option 06", enabled: false },
        ],
        secret: false,
        accessCode: "キーワードサンプル",
      },
      //#endregion Edit


      //#region Options
      planFeatureList: [],
      planCategoryList: [],
      optionList: [],
      //#region Options


      //#region Response data
      response: [],
      //#endregion Response data


      //#region Message
      errorMessage: "",
      //#endregion Message
    };
  },

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },

    uid() {
      return this.$store.state.uniqueId;
    },

    planId() {
      return this.$route.params.planId;
    }
  },

  validations() {
    return {
      edit: {
        id: {},
        planFeature: {},
        planCagetories: {},
      },
    };
  },

  methods: {
    //#region Event
    onUpdateClicked() {
      this.$v.edit.$invalid ? this.$v.edit.$touch() : this.reqPut();
    },
    //#endregion Event


    //#region Request
    reqGet() {
      const url = `/rest/hotel/${this.uid}/plan/${this.planId}`;

      axios
        .get(url, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          this.edit = resp.data;

          this.reqGetPlanCategoryList();
          this.reqGetOptionList();

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.PassError = err.response.status;
          this.loading = false;
        });
    },

    /** プランカテゴリリスト取得 */
    reqGetPlanCategoryList() {
      const url = `/rest/hotel/${this.uid}/plancategory`;

      axios
        .get(url, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          const datas = resp.data;
          const enables = this.edit.planCategories.map(a => a.categoryId);

          this.planCategoryList = datas.list
            .filter(a => a.status === this.STATUS.VALID)
            .map(a => { return { id: a.categoryId, name: a.name, enabled: enables.includes(a.categoryId) } })
            .sort((a, b) => a.id - b.id);
          this.debugLog(this.planCategoryList);

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    /** オプションリスト取得 */
    reqGetOptionList() {
      const url = `/rest/hotel/${this.uid}/option`;

      axios
        .get(url, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          const datas = resp.data;
          const enables = this.edit.planOptions.map(a => a.optionId);

          this.optionList = datas.list
            .filter(a => a.status === this.STATUS.VALID)
            .map(a => { return { id: a.id, name: a.name, enabled: enables.includes(a.id) } })
            .sort((a, b) => a.id - b.id);
          this.debugLog(this.optionList);

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    /** プラン更新 */
    reqPut() {
      const url = `/rest/hotel/${this.uid}/plan/${this.planId}`;

      this.debugLog(this.edit);

      const body = {
        secret: this.edit.secret,
        accessCode: this.edit.accessCode,
      }

      this.debugLog(body);

      axios
        .put(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          this.debugLog(resp.data);

          this.reqPostPlanFeature();
          this.reqPostPlanCategory();
          this.reqPostPlanOption();

          this.loading = false;
          this.successModal = true;
        })
        .catch((err) => {
          // TODO:
          console.log(err);
          this.errorMessage = "エラーメッセージテスト";
        });


    },

    /** プラン特徴更新 */
    reqPostPlanFeature() {
      const url = `/rest/hotel/${this.uid}/plan/${this.planId}/feature`;
      const body = this.edit.features.filter(a => a.enabled).map(a => a.featureId);

      axios
        .post(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          const datas = resp.data;
          console.log(JSON.stringify(datas));
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    /** プランカテゴリ更新 */
    reqPostPlanCategory() {
      const url = `/rest/hotel/${this.uid}/plan/${this.planId}/category`;
      const body = this.planCategoryList.filter(a => a.enabled).map(a => a.id);

      axios
        .post(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          const datas = resp.data;
          console.log(JSON.stringify(datas));
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    /** プランオプション更新 */
    reqPostPlanOption() {
      const url = `/rest/hotel/${this.uid}/plan/${this.planId}/option`;
      const body = this.optionList.filter(a => a.enabled).map(a => a.id);

      axios
        .post(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          const datas = resp.data;
          console.log(JSON.stringify(datas));
          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },
    //#endregion Request

    //#region Method
    //#endregion Method
  },

  beforeMount() {
    this.reqGet();
  },
};
</script>

<style lang="scss" scoped>
.plan-others-from-edit {
  & > .row {
    margin-bottom: 0.5rem;
    .label {
      strong {
        height: 100%;
      }
    }
    .i-field {
      background: #f7f9fc;
      border: 1px solid #cfd5df;
      border-radius: 5px;
      padding-top: 2px;
      padding-bottom: 4px;
      margin-right: 15px;
    }
  }
}
.radio-box {
  li {
    &:first-child {
      background: #cfd5df;
      // font-weight: bold;
    }
    border: 1px solid #cfd5df;
  }
}
</style>
