<template>
  <!-- 
    画面: プラン編集
    用途: プランを更新または削除する
   -->
  <div>

    <!-- #region Main -->
    <CRow>
      <CCol
        md="12"
        lg="12"
      >
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol md="3">
                <strong class="card-title">{{ $t("planEditPage.pageTitle") }}</strong>
              </CCol>
              <CCol>
                <span class="float-right">
                  <CAlert
                    color="info"
                    class="float-left py-0 px-3  my-0 mx-1"
                  ><strong>{{ $t("planEditPage.planName") }} :</strong> {{ planName }}</CAlert>
                </span>
              </CCol>
            </CRow>
          </CCardHeader>

          <CCardBody>
            <CForm class="submit_form">

              <CTabs
                class="edit_tab"
                fade
                :active-tab="$store.state.PlanEditActiveTab"
              >

                <!-- TODO: 新プラン予約の画面補足文章を流用する -->
                <!-- #region 基本設定 -->
                <CTab
                  :title="$t('planEditPage.tab00')"
                  @click.native="activeTab(0)"
                >
                  <PlanEditBasic @plan-name="planName = $event" />
                </CTab>
                <!-- #endregion 基本設定 -->

                <!-- #region タグ -->
                <CTab
                  :title="$t('planEditPage.others')"
                  @click.native="activeTab(1)"
                >
                  <PlanEditOthers />
                </CTab>
                <!-- #endregion タグ -->

                <!-- TODO: これまだ隠しておいてもよい？ポイント機能を会員システムと連携の設計が必要。 -->
                <!-- #region ポイント -->
                <!-- <CTab
                  :title="$t('planEditPage.tab09')"
                  @click.native="activeTab(5)"
                >
                  <PlanEditPoint />
                </CTab> -->
                <!-- #endregion ポイント -->

                <!-- #region 削除 -->
                <CTab
                  :title="$t('planEditPage.tab13')"
                  @click.native="activeTab(2)"
                >
                  <PlanEditDelete />
                </CTab>
                <!-- #endregion 削除 -->

              </CTabs>

            </CForm>
          </CCardBody>

          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
            <!-- #endregion 戻るボタン -->
          </CCardFooter>

        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

  </div>
</template>

<script>
// import axios from "axios";
import PlanEditBasic from "./components/EditBasic";
import PlanEditOthers from "./components/EditOhters";
// import PlanEditPoint from "./components/EditPoint";
import PlanEditDelete from "./components/EditDelete";

export default {
  name: "PlanEdit",

  components: {
    PlanEditBasic,
    PlanEditOthers,
    // PlanEditPoint,
    PlanEditDelete,
  },

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },
  },

  data() {
    return {
      //#region Flag
      loading: false,
      //#endregion Flag

      planName: "",
    };
  },

  methods: {
    //#region Event
    onBackClicked() {
      this.backPage();
    },

    activeTab(TabNo) {
      this.$store.commit("set", ["PlanEditActiveTab", TabNo]);
    },
    //#endregion Event


    //#region Method
    backPage() {
      this.$router.go(-1);
    },
    //#endregion Method
  },

};
</script>
