<template>
  <!-- 
    画面: プラン編集  基本設定タブ
    用途: プランの基本設定を編集する
   -->
  <div class="plan-basic-from-edit">

    <!-- <code>{{ edit }}</code> -->

    <!-- #region Input field -->
    <!-- #region プランタイプ -->
    <CRow>
      <CCol class="label"><strong class="required-mark">{{ $t("planEditPage.planType") }}</strong></CCol>
      <CCol class="i-field">
        <p class="mx-1 mt-1">
          {{ edit.planType | planType }}
        </p>
      </CCol>
    </CRow>
    <!-- #endregion プランタイプ -->

    <!-- #region 名称 -->
    <CRow>
      <CCol class="label"><strong class="required-mark">{{ $t("planEditPage.name") }}</strong></CCol>
      <CCol class="i-field">
        <CInput
          v-model="edit.name"
          @input="onNameInputed()"
        />
        <!-- <p
          class="text-danger input-info"
          v-if="$v.edit.name.$dirty && !$v.edit.name.required"
        >
          {{ $t("validations.required", { vName: $t("planEditPage.name") })}}
        </p> -->
      </CCol>
    </CRow>
    <!-- #endregion 名称 -->

    <!-- #region 名称（インポート用） -->
    <CRow>
      <CCol class="label"><strong>{{ $t("planEditPage.importName") }}</strong></CCol>
      <CCol class="i-field">
        <CInput
          v-model="edit.importName"
          readonly
        />
      </CCol>
    </CRow>
    <!-- #endregion 名称（インポート用） -->

    <!-- #region 概要 -->
    <CRow>
      <CCol class="label"><strong class="required-mark">{{ $t("planEditPage.summary") }}</strong></CCol>
      <CCol class="i-field">
        <!-- <CInput v-model="edit.summary" /> -->
        <CTextarea
          v-model="edit.summary"
          rows="6"
        />
      </CCol>
    </CRow>
    <!-- #endregion 概要 -->

    <!-- #region 掲載サイト -->
    <CRow>
      <CCol class="label"><strong class="required-mark">{{ $t("planEditPage.site") }}</strong></CCol>
      <CCol class="i-field">
        <div class="form-inline mt-1">
          <CInputCheckbox
            class="mx-1"
            v-for="(option, n) in siteList"
            :key="n + option"
            :label="option.name"
            :value="option.id"
            custom
            :checked.sync="option.enabled"
          />
        </div>
      </CCol>
    </CRow>
    <!-- #endregion 掲載サイト -->

    <!-- #region チェックイン開始時刻 -->
    <CRow>
      <CCol class="label"><strong class="required-mark">{{ $t("planEditPage.checkInStartTime") }}</strong></CCol>
      <CCol class="i-field">
        <vue-timepicker
          class="mt-1"
          :minute-interval="10"
          :placeholder="$t('common.timePicker')"
          v-model="edit.checkInStartTime"
          hour-label="時"
          minute-label="分"
          format="HH:mm"
          auto-scroll
        ></vue-timepicker>
      </CCol>
    </CRow>
    <!-- #endregion チェックイン開始時刻 -->

    <!-- #region チェックイン最終時刻 -->
    <CRow>
      <CCol class="label"><strong class="required-mark">{{ $t("planEditPage.checkInEndTime") }}</strong></CCol>
      <CCol class="i-field">
        <vue-timepicker
          class="mt-1"
          :minute-interval="10"
          :placeholder="$t('common.timePicker')"
          v-model="edit.checkInEndTime"
          hour-label="時"
          minute-label="分"
          format="HH:mm"
          auto-scroll
        ></vue-timepicker>
      </CCol>
    </CRow>
    <!-- #endregion チェックイン最終時刻 -->

    <!-- #region チェックアウト時刻 -->
    <CRow>
      <CCol class="label"><strong class="required-mark">{{ $t("planEditPage.checkOutTime") }}</strong></CCol>
      <CCol class="i-field">
        <vue-timepicker
          class="mt-1"
          :minute-interval="10"
          :placeholder="$t('common.timePicker')"
          v-model="edit.checkOutTime"
          hour-label="時"
          minute-label="分"
          format="HH:mm"
          auto-scroll
        ></vue-timepicker>
      </CCol>
    </CRow>
    <!-- #endregion チェックアウト時刻 -->

    <!-- #region 掲載期間 -->
    <CRow class="mb-2">
      <CCol class="label"><strong>{{ $t("planEditPage.postingPeriod") }}</strong></CCol>
      <CCol class="i-field">
        <CRow>
          <CCol>

            <ul class="ul-line-block">
              <li>
                <DatePicker
                  v-model="edit.publicationPeriod.startDate"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="border px-2 py-1 rounded w-6"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </DatePicker>
              </li>
              <li>
                から
              </li>
              <li>
                <DatePicker
                  v-model="edit.publicationPeriod.endDate"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="border px-2 py-1 rounded w-6"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </DatePicker>
              </li>
              <li>
                まで
              </li>
            </ul>

          </CCol>

        </CRow>

        <!-- <CRow>
          <CCol>
            <p>※未設定時は掲載期間を制限しません。</p>
          </CCol>
        </CRow> -->

      </CCol>
    </CRow>

    <!-- #endregion 掲載期間 -->

    <!-- #region 料金設定期間 -->
    <CRow class="mb-2">
      <CCol class="label"><strong class="required-mark">{{ $t("planEditPage.priceSettingPeriod") }}</strong></CCol>
      <CCol class="i-field">

        <CRow>
          <CCol>
            <ul class="ul-line-block">
              <li>
                <DatePicker
                  v-model="edit.pricePeriod.startDate"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="border px-2 py-1 rounded w-6"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </DatePicker>
              </li>
              <li>
                から
              </li>
              <li>
                <DatePicker
                  v-model="edit.pricePeriod.endDate"
                  :popover="{ visibility: 'click' }"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <input
                      class="border px-2 py-1 rounded w-6"
                      :value="inputValue"
                      v-on="inputEvents"
                    />
                  </template>
                </DatePicker>
              </li>
              <li>
                まで
              </li>
            </ul>
          </CCol>

        </CRow>

      </CCol>
    </CRow>
    <!-- #endregion 料金設定期間 -->

    <!-- #region 予約受付締切 -->
    <CRow>
      <CCol class="label"><strong class="required-mark">{{ $t("planEditPage.reserveBefore") }}</strong></CCol>
      <CCol class="i-field">
        <CRow>
          <CCol>
            <ul class="ul-line-block">
              <li>{{ $t('planEditPage.checkIn') }}</li>
              <li>
                <CSelect
                  :options="deadlineDaysAgoes"
                  :value.sync="edit.reserveBeforeDaysAgo"
                />
              </li>
              <li>の</li>
              <li>
                <vue-timepicker
                  class="mt-1"
                  :minute-interval="30"
                  :placeholder="$t('common.timePicker')"
                  v-model="edit.reserveBeforeTime"
                  hour-label="時"
                  minute-label="分"
                  format="HH:mm"
                  auto-scroll
                ></vue-timepicker>
              </li>
              <li>まで</li>
            </ul>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <!-- #endregion 予約受付締切 -->

    <!-- #region キャンセル受付締切 -->
    <!-- TODO: 「予約受付締切と同じ」ボタンを設け、押下時にその値をセットするUIにする -->
    <CRow class="mb-2">
      <CCol class="label"><strong class="required-mark">{{ $t("planEditPage.cancelBefore") }}</strong></CCol>
      <CCol class="i-field">
        <CRow>
          <CCol>
            <CButton
              color="info"
              @click="onSameAsReserveBeforeClick()"
            ><i class="cil-sync"></i> {{$t("planEditPage.sameAsReserveBefore")}}</CButton>

          </CCol>
        </CRow>
        <CRow>
          <CCol>
            <ul class="ul-line-block">
              <li>{{ $t('planEditPage.checkIn') }}</li>
              <li>
                <CSelect
                  :options="deadlineDaysAgoes"
                  :value.sync="edit.cancelBeforeDaysAgo"
                />
              </li>
              <li>の</li>
              <li>

                <vue-timepicker
                  class="mt-1"
                  :minute-interval="30"
                  :placeholder="$t('common.timePicker')"
                  v-model="edit.cancelBeforeTime"
                  hour-label="時"
                  minute-label="分"
                  format="HH:mm"
                  auto-scroll
                ></vue-timepicker>

              </li>
              <li>まで</li>
            </ul>
          </CCol>

        </CRow>
      </CCol>
    </CRow>
    <!-- #endregion キャンセル受付締切 -->

    <!-- #region キャンセル規定 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("planEditPage.cancelPolicy") }}</strong></CCol>
      <CCol class="i-field">
        <CInputRadioGroup
          class="mx-1 mt-1"
          :options="cancelPolicyList"
          :checked.sync="edit.cancelPolicyId"
          inline
        />
      </CCol>
    </CRow>
    <!-- #endregion キャンセル規定 -->

    <!-- #region 販売数制限 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("planEditPage.salesLimit") }}</strong></CCol>
      <CCol class="i-field">

        <ul class="ul-line-block">
          <li>
            <strong>1日</strong>
          </li>
          <li>
            <CInput
              class="w-2"
              v-model.number="edit.saleLimitCount"
              type="number"
            />
          </li>
          <li>
            <CSelect
              :options="saleLimitTypes"
              :value.sync="edit.saleLimitType"
              inline
            />
          </li>
          <li>
            <strong>限定</strong>
          </li>
        </ul>

      </CCol>

    </CRow>
    <!-- #endregion 販売数制限 -->

    <!-- #region 人数制限 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("planEditPage.countLimit") }}</strong></CCol>
      <CCol class="i-field">

        <ul class="ul-line-block">
          <li>
            予約1件
          </li>
          <li>
            <CInput
              type="number"
              class="w-1"
              :value.sync="edit.minPeople"
            />
          </li>
          <li>名から</li>
          <li>
            <CInput
              type="number"
              class="w-1"
              :value.sync="edit.maxPeople"
            />
          </li>
          <li>名まで受付</li>
        </ul>

      </CCol>
    </CRow>
    <!-- #endregion 人数制限 -->

    <!-- #region 泊数制限 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("planEditPage.restLimit") }}</strong></CCol>
      <CCol class="i-field">
        <CRow>
          <CCol>

            <ul class="ul-line-block">
              <li>
                <CInput
                  v-model="edit.minNights"
                  class="w-1"
                  type="number"
                />
              </li>
              <li>
                {{ $t("planEditPage.restFrom") }}
              </li>
              <li>
                <CInput
                  v-model="edit.maxNights"
                  class="w-1"
                  type="number"
                />
              </li>
              <li>
                {{ $t("planEditPage.restTo") }}
              </li>
            </ul>
          </CCol>

        </CRow>

      </CCol>
    </CRow>
    <!-- #endregion 泊数制限 -->

    <!-- #region 決済方法 -->
    <!-- TODO: API待ち -->
    <CRow>
      <CCol class="label"><strong>{{ $t("planEditPage.clearing") }}</strong></CCol>
      <CCol class="i-field">
        <CInputCheckbox
          class="mx-1 mt-1"
          v-for="(option, n) in edit.payments"
          :key="n + option"
          :label="option.feature.name"
          :value="option.featureId"
          custom
          :checked.sync="option.enabled"
          inline
        />
      </CCol>
    </CRow>
    <!-- #endregion 決済方法 -->

    <!-- #region 夕食 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("planEditPage.dinner") }}</strong></CCol>
      <CCol class="i-field">
        <CInputRadioGroup
          class="mx-1 mt-1"
          :options="mealTypes"
          :checked.sync="edit.dinnerType"
          inline
        />
      </CCol>
    </CRow>
    <!-- #endregion 夕食 -->

    <!-- #region 朝食 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("planEditPage.breakfast") }}</strong></CCol>
      <CCol class="i-field">
        <CInputRadioGroup
          class="mx-1 mt-1"
          :options="mealTypes"
          :checked.sync="edit.breakfastType"
          inline
        />
      </CCol>
    </CRow>
    <!-- #endregion 朝食 -->

    <!-- #region 昼食 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("planEditPage.lunch") }}</strong></CCol>
      <CCol class="i-field">
        <CInputRadioGroup
          class="mx-1 mt-1"
          :options="mealTypes"
          :checked.sync="edit.lunchType"
          inline
        />
      </CCol>
    </CRow>
    <!-- #endregion 昼食 -->

    <!-- #region 画像 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("imageGallery.image") }}</strong></CCol>
      <CCol class="i-field">
        <!-- TODO: ImageSelectModalにバグがあるかも -->
        <ImageSelectModal
          :images="edit.images"
          @selected-images="edit.images =$event"
          :isDraggable="false"
          :key="edit.images"
        />
      </CCol>
    </CRow>
    <!-- #endregion 画像 -->

    <!-- #region 部屋タイプ -->
    <CRow>
      <CCol class="label"><strong>{{$t('planEditPage.roomType')}}</strong></CCol>
      <CCol class="i-field py-2">
        <!-- <EnableSelectionList :list="edit.roomTypes" /> -->
        <EnableSelectionList :list="roomTypeList" />
        <!-- <p>※有効にして更新された部屋タイプを無効に戻すことはできません。</p> -->
        <p>{{$t('planEditPage.roomTypeDescription')}}</p>
        <!-- TODO: 上記制御をコンポーネントに追加する -->
      </CCol>
    </CRow>
    <!-- #endregion 部屋タイプ -->

    <!-- #region 予約質問 -->
    <CRow>
      <CCol class="label"><strong>{{$t('planEditPage.question')}}</strong></CCol>
      <CCol class="i-field py-2">
        <EnableSelectionList
          :list="questionList"
          :leftListTitle="$t('common.active')"
          :rightListTitle="$t('common.inActive')"
        />
      </CCol>
    </CRow>
    <!-- #endregion 予約質問 -->

    <!-- #region 特記事項 -->
    <CRow>
      <CCol class="label"><strong>{{ $t("planEditPage.notice") }}</strong></CCol>
      <CCol class="i-field py-2">
        <CTextarea
          v-model="edit.notices"
          rows="6"
        />
      </CCol>
    </CRow>
    <!-- #endregion 特記事項 -->
    <!-- #endregion Input field -->

    <!-- #region 更新ボタン -->
    <CButton
      class="px-5 mt-4"
      color="success"
      @click="onUpdateClicked()"
      v-bind:class="activeStatus"
    >
      <i class="cil-save"></i> {{ $t("common.update") }}
    </CButton>
    <!-- #endregion 更新ボタン -->

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />

  </div>
</template>

<script>
import axios from "axios";
import i18n from "@/i18n";
import VueTimepicker from "vue2-timepicker";
import ImageSelectModal from "@/components/ImageSelectModal.vue";
import EnableSelectionList from "@/components/EnableSelectionList";
import STATUS from "@/mixins/property";
import utils from "@/mixins/utils";
// import Moment from "moment";

// CSS
import "vue2-timepicker/dist/VueTimepicker.css";

// import {
//   // required,
//   // numeric,
//   // minLength,
//   // maxLength,
//   // helpers,
// } from "vuelidate/lib/validators";

export default {
  name: "PlanEditBasic",

  mixins: [STATUS, utils],

  components: {
    VueTimepicker,
    ImageSelectModal,
    EnableSelectionList,
  },

  props: {},

  computed: {
    activeStatus() {
      return this.$v.edit.$invalid ? "inactive" : "active";
    },

    uid() {
      return this.$store.state.uniqueId;
    },

    planId() {
      return this.$route.params.planId;
    },

    selectedSiteIds() {
      return this.siteList.filter(a => a.enabled).map(a => { return { siteId: a.id } });
    },

    selectedPaymentIds() {
      return this.edit.payments.filter(a => a.enabled).map(a => { return { featureId: a.featureId } });
    },

    selectedRoomTypeIds() {
      return this.roomTypeList.filter(a => a.enabled).map(a => { return { roomId: a.id } });
    },

    selectedQuestionIds() {
      console.log(this.questionList, this.questionList.filter(a => a.enabled).map(a => { return { questionId: a.id } }));
      return this.questionList.filter(a => a.enabled).map(a => { return { questionId: a.id } });
    },

    selectedCancelPolicyId() {
      return { id: Number(this.edit.cancelPolicyId) };
    },

    saleLimitTypeDefault() {
      return 1; // 組
    },

  },

  data() {
    return {
      //#region Flag
      loading: false,
      successModal: false,
      errorModal: false,
      //#endregion Flag


      //#region Edit
      // edit: {},
      edit: {
        category: {
          id: "",
          name: "",
          description: "",
          hotelId: ""
        },
        publicationPeriodId: "",
        pricePeriodId: "",
        language: "",
        id: "",
        hotelId: "",
        planType: null,
        name: "",
        description: "",
        summary: "",
        importName: "",
        maxPeople: null,
        minPeople: null,
        maxNights: null,
        minNights: null,
        sites: [],
        images: [],
        properties: [],
        displayOrder: null,
        status: null,
        planRooms: [],
        planQuestions: [],
        planOptions: [],
        checkInStartTime: "00:00",
        checkInEndTime: "00:00",
        checkOutTime: "00:00",
        publicationPeriod: { startDate: "", endDate: "" },
        pricePeriod: { startDate: "", endDate: "" },
        reserveBefore: null,
        reserveBeforeDaysAgo: null,
        reserveBeforeTime: null,
        cancelBefore: null,
        cancelBeforeDaysAgo: null,
        cancelBeforeTime: null,
        cancelPolicyId: "",
        // cancelPolicy: {
        //   id: null,
        //   hotelId: null,
        //   name: null,
        //   description: null,
        //   status: null,
        //   displayOrder: null,
        //   policyItems: []
        // },
        saleLimitCount: null,
        saleLimitType: null,
        dinnerType: null,
        breakfastType: null,
        lunchType: null,
        secret: null,
        notices: null
      },

      //#endregion Edit


      //#region Options
      siteList: [],
      roomTypeList: [],
      questionList: [],

      // 支払方法
      paymentTypeList: [
        { value: "1", label: `${i18n.t("planEditPage.onSitePayment")}`, enabled: false },
        { value: "2", label: `${i18n.t("planEditPage.onlinePayment")}`, enabled: false },
      ],

      /** 掲載期間設定するか？ */
      periodTypes: [
        { value: "none", label: `${i18n.t("planEditPage.noDeadline")}` },
        { value: "setting", label: `${i18n.t("planEditPage.hasDeadline")}` },
      ],

      /** 予約キャンセル締切時間 options */
      deadlineDaysAgoes: [
        // { value: 3, label: `3${i18n.t("planEditPage.daysAge")}` },
        // { value: 2, label: `2${i18n.t("planEditPage.daysAge")}` },
        // { value: 1, label: `1${i18n.t("planEditPage.daysAge")}` },
        // { value: 0, label: `${i18n.t("planEditPage.currentDay")}` },
      ],

      /** 予約キャンセル締切時間 options */
      cancelBeforeTypes: [
        { value: "same", label: `${i18n.t("planEditPage.same")}` },
        { value: "another", label: `${i18n.t("planEditPage.other")}` },
      ],

      /** キャンセル規定 options */
      cancelPolicyList: [
        { value: "1", label: "" },
      ],

      /** 販売数制限 options */
      settingList: [
        { value: false, label: `${i18n.t("planEditPage.noLimit")}` },
        { value: true, label: `${i18n.t("planEditPage.hasLimit")}` },
      ],

      /** 1日当たりの販売数制限単位 options */
      saleLimitTypes: [
        { value: 1, label: `${i18n.t("planEditPage.group")}` },
        { value: 2, label: `${i18n.t("planEditPage.room")}` },
      ],

      /** 食事場所リスト options */
      mealTypes: [
        { value: "0", label: `${i18n.t("planEditPage.noMeal")}` },
        { value: "1", label: `${i18n.t("planEditPage.unspecified")}` },
        { value: "2", label: `${i18n.t("planEditPage.private")}` },
        { value: "3", label: `${i18n.t("planEditPage.myRoom")}` },
      ],
      //#endregion Options


      //#region Response data
      response: [],
      //#endregion Response data


      //#region Message
      errorMessage: "",
      //#endregion Message
    };
  },

  validations() {
    return {
      edit: {
        id: {},
        name: {},
        // name: { required },
        // importName: { required },
        importName: {},
        summary: {},
        introduction: {},
        images: {},

        description: {},
        sites: {},
        checkInStartTime: {},
        checkInEndTime: {},
        checkOutTime: {},
        // checkInStartTime: { required },
        // checkInEndTime: { required },
        // checkOutTime: { required },
        isSalesLimit: {},
        salesLimitType: {},
        isPersonsLimit: {},
        personsLimitMin: {},
        personsLimitMax: {},
        restFrom: {},
        restTo: {},
        basicPointGrantRate: {},
        pointEffectivePeriod: {},
        paymentTypes: {},
      },
    };
  },

  methods: {
    //#region Event
    /** 更新ボタン押下 */
    onUpdateClicked() {
      this.$v.edit.$invalid ? this.$v.edit.$touch() : this.reqPut();
    },

    onNameInputed() {
      this.setImportName();
      this.emit();
    },

    onSameAsReserveBeforeClick() {
      this.setCancelBeforeFromReserveBefore();
    },
    //#endregion Event


    //#region Request
    reqGet() {
      this.loading = true;

      const url = `/rest/hotel/${this.uid}/plan/${this.planId}`;

      axios
        .get(url, {
          headers: { Authorization: `Bearer ${this.$keycloak.token}` },
        })
        .then((resp) => {
          let datas = resp.data;
          this.debugLog(datas);

          // 未設定のオブジェクトを初期化
          datas.cancelPolicyId = this.getCancelPolicyId(datas.cancelPolicy);
          datas.saleLimitType = datas.saleLimitType || this.saleLimitTypeDefault;
          datas.publicationPeriod = datas.publicationPeriod || { startDate: "", endDate: "" };
          datas.pricePeriod = datas.pricePeriod || { startDate: "", endDate: "" };
          datas.checkInStartTime = this.stringToTimepicker(datas.checkInStartTime);
          datas.checkInEndTime = this.stringToTimepicker(datas.checkInEndTime);
          datas.checkOutTime = this.stringToTimepicker(datas.checkOutTime);
          datas.dinnerType = this.getRadioValueString(datas.dinnerType);
          datas.breakfastType = this.getRadioValueString(datas.breakfastType);
          datas.lunchType = this.getRadioValueString(datas.lunchType);
          datas.reserveBeforeDaysAgo = this.getSecondsBeforeToDeadlineDays(datas.reserveBefore);
          datas.reserveBeforeTime = this.getSecondsBeforeToDeadlineTime(datas.reserveBefore);
          datas.cancelBeforeDaysAgo = this.getSecondsBeforeToDeadlineDays(datas.cancelBefore);
          datas.cancelBeforeTime = this.getSecondsBeforeToDeadlineTime(datas.cancelBefore);

          this.edit = datas;
          this.setImportName();
          this.debugLog(this.edit);

          // NOTE: プラン名表示のため
          this.emit();

          this.reqGetFacilitySiteList();
          this.reqGetFacilityCancelPolicyList();
          this.reqGetFacilityRoomTypeList();
          this.reqGetFacilityQuestionList();

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.PassError = err.response.status;
          this.loading = false;
        });
    },

    /** 掲載サイトリスト取得 */
    reqGetFacilitySiteList() {
      const url = `/rest/hotel/${this.uid}/site`;

      axios
        .get(url, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          const datas = resp.data;
          this.debugLog(datas);

          const enables = this.edit.sites.map(a => a.siteId);
          this.debugLog(enables);

          this.siteList = datas.list
            // note: 宿と掲載サイトのリレーションのためステータスなし（filter不要）
            // .filter(a => a.status === this.STATUS.VALID) 
            .map(a => {
              return { id: a.site.id, name: a.site.name, enabled: enables.includes(a.site.id) }
            })
            .sort((a, b) => a.id - b.id);

          this.debugLog(this.siteList);

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    /** キャンセル規定リスト取得 */
    reqGetFacilityCancelPolicyList() {
      const url = `/rest/hotel/${this.uid}/cancelpolicy`;

      axios
        .get(url, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          const datas = resp.data;
          this.debugLog(datas);

          this.cancelPolicyList = datas.policies
            .filter(a => a.status === 1) // NOTE: キャンセル規定は status=1が有効, status=0が無効
            .map(a => { return { value: String(a.id), label: a.name } })
            .sort((a, b) => a.id - b.id);
          this.debugLog(this.cancelPolicyList);

          // TODO: キャンセル規定を選択する処理
          // this.edit.cancelPolicyId = this.getCancelPolicyId(this.edit.cancelPolicy);
          // this.debugLog(this.edit.cancelPolicyId);

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    /** 部屋タイプリスト取得 */
    reqGetFacilityRoomTypeList() {
      const url = `/rest/hotel/${this.uid}/room`;

      axios
        .get(url, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          const datas = resp.data;
          this.debugLog(datas);

          const enables = this.edit.planRooms.map(a => a.roomId);
          this.debugLog(enables);

          this.roomTypeList = datas.rooms
            .filter(a => a.status === this.STATUS.VALID)
            .map(a => {
              return { id: a.id, name: a.name, enabled: enables.includes(a.id) }
            })
            .sort((a, b) => a.id - b.id);
          this.debugLog(this.roomTypeList);

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    /** 質問リスト取得 */
    reqGetFacilityQuestionList() {
      const url = `/rest/hotel/${this.uid}/question`;

      axios
        .get(url, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          const datas = resp.data;
          this.debugLog(datas);

          const enables = this.edit.planQuestions.map(a => a.questionId);
          this.debugLog(this.enables);

          this.questionList = datas.list
            .filter(a => a.status === this.STATUS.VALID)
            .map(a => { return { id: a.id, name: a.questionText, enabled: enables.includes(a.id) } })
            .sort((a, b) => a.id - b.id);
          this.debugLog(this.questionList);

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    /** プラン更新 */
    reqPut() {
      const url = `/rest/hotel/${this.uid}/plan/${this.planId}`;

      this.debugLog(this.edit);

      const body = {
        planType: this.edit.planType,
        name: this.edit.name,
        importName: this.edit.importName,
        summary: this.edit.summary,
        sites: this.selectedSiteIds,
        checkInStartTime: this.timepickerString(this.edit.checkInStartTime),
        checkInEndTime: this.timepickerString(this.edit.checkInEndTime),
        checkOutTime: this.timepickerString(this.edit.checkOutTime),
        publicationPeriod: this.getPeriodObject(this.edit.publicationPeriod),
        pricePeriod: this.getPeriodObject(this.edit.pricePeriod),
        reserveBefore: this.getDeadlineSecondsBefore(this.edit.reserveBeforeTime, this.edit.reserveBeforeDaysAgo),
        cancelBefore: this.getDeadlineSecondsBefore(this.edit.cancelBeforeTime, this.edit.cancelBeforeDaysAgo),
        cancelPolicy: this.selectedCancelPolicyId,
        saleLimitCount: this.edit.saleLimitCount,
        saleLimitType: this.edit.saleLimitType,
        minPeople: this.edit.minPeople,
        maxPeople: this.edit.maxPeople,
        minNights: this.edit.minNights,
        maxNights: this.edit.maxNights,
        payments: this.selectedPaymentIds,
        lunchType: Number(this.edit.lunchType),
        dinnerType: Number(this.edit.dinnerType),
        breakfastType: Number(this.edit.breakfastType),
        images: this.edit.images,
        planRooms: this.selectedRoomTypeIds,
        planQuestions: this.selectedQuestionIds,
        notices: this.edit.notices,
      }

      this.debugLog(body);

      axios
        .put(url, body, {
          headers: {
            'Authorization': `Bearer ${this.$keycloak.token}`,
            'Accept-Language': this.$i18n.locale,
            // 'Accept-Language': `*`,
          },
        })
        .then((resp) => {
          this.debugLog(resp.data);

          this.reqGet();

          this.loading = false;
          this.checkModal = false;
          this.successModal = true;
        })
        .catch((err) => {
          // TODO:
          console.log(err);
          this.errorMessage = "エラーメッセージテスト";
        });
    },
    //#endregion Request

    //#region Method
    /** 名称（インポート用）は、名称と同じ値を文字数制限で設定 */
    setImportName() {
      this.edit.importName = this.edit.name.substring(0, 50);
    },

    getRadioValueString(value) {
      return (value == null) ? "0" : String(value);
    },

    getCancelPolicyId(cancelPolicy) {
      return (cancelPolicy == null) ? "0" : String(cancelPolicy.id);
    },

    emit() {
      this.$emit("plan-name", this.edit.name);
    },

    initDeadlineDaysAgoes() {
      this.deadlineDaysAgoes = [];

      // 1～60日前を降順でセット
      for (let i = 60; i > 0; i--) {
        this.deadlineDaysAgoes.push({ value: i, label: `${i}${i18n.t("planEditPage.daysAge")}` });
      }

      // 当日をセット
      this.deadlineDaysAgoes.push({ value: 0, label: `${i18n.t("planEditPage.currentDay")}` });
    },

    setCancelBeforeFromReserveBefore() {
      this.edit.cancelBeforeDaysAgo = this.edit.reserveBeforeDaysAgo;
      this.edit.cancelBeforeTime = this.edit.reserveBeforeTime;
    },
    //#endregion Method
  },

  beforeMount() {
    this.initDeadlineDaysAgoes();
    this.reqGet();
  },
};
</script>

<style lang="scss" scoped>
.plan-basic-from-edit {
  & > .row {
    margin-bottom: 0.5rem;
    .label {
      strong {
        height: 100%;
      }
    }
    .i-field {
      background: #f7f9fc;
      border: 1px solid #cfd5df;
      border-radius: 5px;
      padding-top: 2px;
      padding-bottom: 4px;
      margin-right: 15px;
    }
  }
}
.radio-box {
  li {
    &:first-child {
      background: #cfd5df;
      // font-weight: bold;
    }
    border: 1px solid #cfd5df;
  }
}
</style>
